// // App.jsx
// import React from 'react';
// import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
// import Profile from './Profile';

// const profiles = [
//   { id: 1, name: 'Alice', role: 'Developer' },
//   { id: 2, name: 'Bob', role: 'Designer' },
//   { id: 3, name: 'Charlie', role: 'Manager' },
// ];

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<h2>Select a profile</h2>} />
//           <Route path="/profile/:id" element={<Profile />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;


import React from "react";
import logo from "./logo.svg";
import "./App.css";
 
import ErrorPage from "./pages/error/ErrorPage";
import { BrowserRouter } from "react-router-dom";
import BasicRoutes from "./routes/index";
import firebase from "./firebase";


function App() {
  return (
          <BrowserRouter>
            <ErrorPage>
              <BasicRoutes />
            </ErrorPage>
          </BrowserRouter>
  );
}

export default App;
