import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { database, storage, auth } from '../../firebase';
import { ref, set, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import banner from '../../assets/banner.jpg';

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  CircularProgress,
  Grid,
  IconButton,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const EditProfileForm = () => {
  const { profileId, deviceId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEducation, setShowEducation] = useState(true);
  const [showWellness, setShowWellness] = useState(true);
  const [user, setUser] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    homeAddress: '',
    nricFin: '',
    school: '',
    childClass: '',
    bloodGroup: '',
    allergies: '',
    medicalCondition: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    localAddress: '',
    permanentAddress: '',
    agreeToShare: false,
    profileType: '',
    institutionName: '',
    institutionAddress: '',
    ClassTeacher: '',
    principal: '',
    diet: '',
    dietInstructions: '',
    medication: '',
    insurance: '',
    doctor: '',
    comments: '',
    additionalInformation: '',
    picture: null,
    emergencyContacts: [{ name: '', number: '' }],
   
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchProfileData(currentUser.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate, deviceId, profileId]);

  const fetchProfileData = async (userId) => {
    try {
      const profileRef = ref(database, `devices/${deviceId}/profiles/${profileId}`);
      const snapshot = await get(profileRef);
      if (snapshot.exists()) {
        const profileData = snapshot.val();
        setFormData(profileData);
        setShowEducation(profileData.showEducation);
        setShowWellness(profileData.showWellness);
      } else {
        throw new Error("Profile not found");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      alert("Error loading profile data. Please try again.");
      navigate('/dashboard');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      agreeToShare: e.target.checked
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) { // 10 MB limit
      setFormData(prevState => ({
        ...prevState,
        picture: file
      }));
    } else {
      alert('File size should not exceed 10 MB');
    }
  };

  const handleEmergencyContactChange = (index, field, value) => {
    const updatedContacts = formData.emergencyContacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: updatedContacts
    }));
  };

  const addEmergencyContact = () => {
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: [...prevState.emergencyContacts, { name: '', number: '' }]
    }));
  };

  const removeEmergencyContact = (index) => {
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: prevState.emergencyContacts.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!user) {
        throw new Error('You must be logged in to edit a profile');
      }

      let imageUrl = formData.picture;
      if (formData.picture instanceof File) {
        const imageRef = storageRef(storage, `pictures/${deviceId}/${Date.now()}`);
        await uploadBytes(imageRef, formData.picture);
        imageUrl = await getDownloadURL(imageRef);
      }

      const updatedProfileData = {
        ...formData,
        picture: imageUrl,
        showEducation,
        showWellness,
        updatedAt: Date.now(),
        updatedBy: user.uid
      };

      await set(ref(database, `devices/${deviceId}/profiles/${profileId}`), updatedProfileData);

      alert('Profile updated successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error("Error updating profile:", error);
      alert('An error occurred while updating the profile: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const classOptions = ['IFC', 'PG', 'N1', 'N2', 'K1', 'K2'];
  const dietOptions = ['Vegetarian', 'Vegan', 'Non-Vegetarian', 'Non-Vegetarian(Halal)'];

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, height: "100vh", overflow: "auto" }}>
      <Box sx={{ width: '100%', height: 200, overflow: 'hidden' }}>
        <img
          src={banner}
          alt="InstaLink wristband and phone"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
      <CardHeader
        title="Edit Profile"
        subheader="Update your InstaShare profile information"
      />

      <CardContent>
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          {/* You can reuse most of the form fields from InstaShareForm */}
          {/* Remember to use formData values for each field */}

          {/* Example of a form field: */}
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />

          {/* Add all other form fields here */}

          <TextField
            fullWidth
            margin="normal"
            select
            label="Profile Type"
            name="profileType"
            value={formData.profileType}
            onChange={handleInputChange}
          >
            <MenuItem value="kids">Kids</MenuItem>
            <MenuItem value="patient">Patient</MenuItem>
            <MenuItem value="business">Business</MenuItem>
          </TextField>
          <div>
            <span>Personal Data:</span>
          </div>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="nricFin"
            label="NRIC/FIN"
            value={formData.nricFin}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="localAddress"
            label="Home Address"
            multiline
            rows={3}
            value={formData.localAddress}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="permanentAddress"
            label="Permanent Address"
            multiline
            rows={3}
            value={formData.permanentAddress}
            onChange={handleInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <span>Education</span>
            </div>

            <Box sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showEducation}
                  onChange={(e) => setShowEducation(e.target.checked)}
                  name="showEducation"
                />
              }
              label="Show Education Section"
            />
            </Box>
          </Box>


          <TextField
            fullWidth
            margin="normal"
            select
            name="school"
            label="School"
            value={formData.school}
            onChange={handleInputChange}
          >
            <MenuItem value="">Choose</MenuItem>
            <MenuItem value="school1">LEAP SchoolHouse (CSM) </MenuItem>
            <MenuItem value="school2">Mind Champ (CSM) </MenuItem>
            <MenuItem value="school3">Other </MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            name="institutionAddress"
            label="Institution Address"
            multiline
            rows={3}
            value={formData.institutionAddress}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="class-label">Class</InputLabel>
            <Select
              labelId="class-label"
              name="childClass"
              value={formData.childClass}
              onChange={handleInputChange}
              label="Child is in Class"
            >
              <MenuItem value="">Choose</MenuItem>
              {classOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="ClassTeacher"
            label="Class Teacher"
            value={formData.ClassTeacher}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="principal"
            label="Principal"
            value={formData.principal}
            onChange={handleInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <span>Wellness</span>
            </div>

            <Box sx={{ mt: 2, mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showWellness}
                    onChange={(e) => setShowWellness(e.target.checked)}
                    name="showWellness"
                  />
                }
                label="Show Wellness Section"
              />
            </Box>
          </Box>
     
          <TextField
            fullWidth
            margin="normal"
            name="bloodGroup"
            label="Child's Blood Group"
            value={formData.bloodGroup}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="class-label">Diet</InputLabel>
            <Select
              labelId="class-label"
              name="diet"
              value={formData.diet}
              onChange={handleInputChange}
              label="Diet"
            >
              <MenuItem value="">Choose</MenuItem>
              {dietOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            name="dietInstructions"
            label="Diet Instructions"
            value={formData.dietInstructions}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="allergies"
            label="Child is Allergic to"
            value={formData.allergies}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="medication"
            label="Medication"
            value={formData.medication}
            onChange={handleInputChange}
          />


          <TextField
            fullWidth
            margin="normal"
            name="medicalCondition"
            label="Any known Medical Condition"
            multiline
            rows={3}
            value={formData.medicalCondition}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="insurance"
            label="Insurance"

            value={formData.insurance}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="doctor"
            label="Doctor/Hospital"

            value={formData.doctor}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="comments"
            label="Comments"

            value={formData.comments}
            onChange={handleInputChange}
          />

          <div>
            <span>Emergency</span>
          </div>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Emergency Contacts</Typography>
            {formData.emergencyContacts.map((contact, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={contact.name}
                      onChange={(e) => handleEmergencyContactChange(index, 'name', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Number"
                      value={contact.number}
                      onChange={(e) => handleEmergencyContactChange(index, 'number', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {index > 0 && (
                      <IconButton onClick={() => removeEmergencyContact(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addEmergencyContact}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              Add Emergency Contact
            </Button>
          </Grid>


          <Box sx={{ mt: 2, mb: 2 }}>
            <span>Additional Information</span>
          </Box>

          <TextField
            fullWidth
            margin="normal"
            name="additionalInformation"
            label="Additional Information"
            multiline
            rows={3}
            value={formData.additionalInformation}
            onChange={handleInputChange}
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle1">
              Upload  picture (this is display picture)
            </Typography>
            <Typography variant="caption">
              Upload 1 supported file. Max 10 MB.
            </Typography>
            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="child-picture-upload"
            />
            <label htmlFor="child-picture-upload">
              <Button variant="outlined" component="span" startIcon={<span>↑</span>}>
                Add File
              </Button>
            </label>
            {formData.childPicture && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                File selected: {formData.childPicture.name}
              </Typography>
            )}
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isSubmitting ? 'Updating...' : 'Update Profile'}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={() => navigate('/dashboard')}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditProfileForm;