import React, { useEffect, useState } from 'react';
import { auth, database } from '../../firebase'; // Update the import path as needed
import { ref, get, update } from 'firebase/database';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import {
  CircularProgress,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  AppBar,
  Toolbar,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const UserProfiles = () => {
  const [devices, setDevices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error("Error signing out: ", error);
      setError("Failed to log out. Please try again.");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserDevicesAndProfiles(user.uid);
      } else {
        setDevices({});
        setLoading(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUserDevicesAndProfiles = async (userId) => {
    setLoading(true);
    try {
      const userDevicesRef = ref(database, `users/${userId}/devices`);
      const userDevicesSnapshot = await get(userDevicesRef);

      if (userDevicesSnapshot.exists()) {
        const deviceIds = Object.keys(userDevicesSnapshot.val());
        const deviceData = {};

        for (const deviceId of deviceIds) {
          const deviceRef = ref(database, `devices/${deviceId}`);
          const deviceSnapshot = await get(deviceRef);

          if (deviceSnapshot.exists()) {
            const device = deviceSnapshot.val();
            deviceData[deviceId] = {
              defaultProfileId: device.defaultProfileId,
              profiles: device.profiles || {}
            };
          }
        }

        setDevices(deviceData);
      } else {
        setDevices({});
      }
    } catch (err) {
      console.error("Error fetching user devices and profiles:", err);
      setError("Failed to load profiles. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleDefault = async (deviceId, profileId) => {
    try {
      await update(ref(database, `devices/${deviceId}`), {
        defaultProfileId: profileId
      });

      setDevices(prevDevices => ({
        ...prevDevices,
        [deviceId]: {
          ...prevDevices[deviceId],
          defaultProfileId: profileId
        }
      }));
    } catch (error) {
      console.error("Error setting default profile:", error);
      alert('Failed to set default profile. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "#000" }}>
          <Box sx={{ display:"flex", gap:2, flexGrow: 1 }}>
            <Typography variant="h5" component="div" >
              Dashboard
            </Typography>
            <Typography variant="h5" component="div" >
              About Us
            </Typography>
            <Typography variant="h5" component="div" >
              Contact
            </Typography>
          </Box>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100vw", maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Devices and Profiles
        </Typography>
        {Object.keys(devices).length === 0 ? (
          <Typography>You haven't created any devices or profiles yet.</Typography>
        ) : (
          Object.entries(devices).map(([deviceId, deviceData]) => (
            <Card key={deviceId} sx={{ mb: 4 }}>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h6" gutterBottom>Device ID: {deviceId}</Typography>                <Button
                    component={Link}
                    to={`/em/${deviceId}`}
                    variant="outlined"
                    sx={{ ml: 1 }}
                  >
                    View
                  </Button>
                </Box>
                <List>
                  {Object.entries(deviceData.profiles).map(([profileId, profile]) => (
                    <ListItem key={profileId}>
                      <ListItemText
                        primary={profile.name}
                        secondary={`Type: ${profile.profileType}`}
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          edge="end"
                          onChange={() => handleToggleDefault(deviceId, profileId)}
                          checked={profileId === deviceData.defaultProfileId}
                        />

                        <IconButton
                          edge="end"
                          aria-label="edit"
                          component={Link}
                          to={`/edit/${deviceId}/${profileId}`}
                          sx={{ ml: 1 }}
                        >
                          <EditIcon />
                        </IconButton>

                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <Button
                  component={Link}
                  to={`/register/${deviceId}`}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Add Profile
                </Button>
              </CardContent>
            </Card>
          ))
        )}
        <Box mt={4}>
          <Button component={Link} to="/newregister" variant="contained" color="primary">
            Register New Device
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserProfiles;