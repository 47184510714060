import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { BrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import InstaShareForm from "../pages/basicDetails/instashare-form-component";
import EditProfileForm  from "../pages/basicDetails/EditProfileForm";
import Profile from "../pages/profile/Profile";
import Device from "../pages/device/Device";
import UserProfiles from '../pages/userProfiles/UserProfiles';

import Login from '../pages/login/Login';
import ProtectedRoute from './ProtectedRoute';

export default function BasicRoutes() {

  return (
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <UserProfiles />
        </ProtectedRoute>
      } />

      <Route path="/" element={
        <ProtectedRoute>
          <UserProfiles />
        </ProtectedRoute>
      } />
      <Route path="/register/:typeofuser/:deviceId" element={<InstaShareForm />} />
      <Route path="/register/" element={<InstaShareForm />} />
      <Route path="/register/:deviceId" element={<ProtectedRoute>
        <InstaShareForm />
      </ProtectedRoute>} />
      <Route path="/newregister" element={<ProtectedRoute>
        <InstaShareForm />
      </ProtectedRoute>} />
      <Route path="/edit/:deviceId/:profileId" element={<ProtectedRoute><EditProfileForm /></ProtectedRoute>} />
      <Route path="/em/:deviceId" element={<Profile />} />
      <Route path="/device/:deviceId" element={<Device />} />
      <Route path="/login" element={<Login />} />

    </Routes>
  )
}
