import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { database, storage, auth } from '../../firebase'; // Update the import path as needed
import { ref, push, set, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import banner from '../../assets/banner.jpg';

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  CircularProgress,
  Grid,
  IconButton, Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const InstaShareForm = () => {
  const { typeofuser, deviceId: urlDeviceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEducation, setShowEducation] = useState(true);
  const [showWellness, setShowWellness] = useState(true);
  const [user, setUser] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    homeAddress: '',
    nricFin: '',
    school: '',
    childClass: '',
    bloodGroup: '',
    allergies: '',
    medicalCondition: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    localAddress: '',
    permanentAddress: '',
    agreeToShare: false,
    typeofuser: typeofuser ? typeofuser : '',
    deviceId: urlDeviceId || '',
    emergencyContacts: [{ name: '', number: '' }],
    profileType: '',
    institutionName: '',
    institutionAddress: '',
    ClassTeacher: '',
    principal: '',
    diet: '',
    dietInstructions: '',
    medication: '',
    insurance: '',
    doctor: '',
    comments: '',
    additionalInformation: '',
    picture: null
  });

  useEffect(() => {
    // You can perform any necessary actions with typeofuser and deviceId here
    console.log('Type of user:', typeofuser);
    console.log('Device ID:', urlDeviceId);
  }, [typeofuser, urlDeviceId]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      agreeToShare: e.target.checked
    }));
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) { // 10 MB limit
      setFormData(prevState => ({
        ...prevState,
        picture: file
      }));
    } else {
      alert('File size should not exceed 10 MB');
    }
  };

  const handleEmergencyContactChange = (index, field, value) => {
    const updatedContacts = formData.emergencyContacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: updatedContacts
    }));
  };

  const addEmergencyContact = () => {
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: [...prevState.emergencyContacts, { name: '', number: '' }]
    }));
  };

  const removeEmergencyContact = (index) => {
    setFormData(prevState => ({
      ...prevState,
      emergencyContacts: prevState.emergencyContacts.filter((_, i) => i !== index)
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!user) {
        throw new Error('You must be logged in to create a profile');
      }

      let imageUrl = '';
      if (formData.picture) {
        const imageRef = storageRef(storage, `pictures/${formData.deviceId}/${Date.now()}`);
        await uploadBytes(imageRef, formData.picture);
        imageUrl = await getDownloadURL(imageRef);
        debugger
      }

      const deviceRef = ref(database, `devices/${formData.deviceId}`);
      const deviceSnapshot = await get(deviceRef);

      if (!deviceSnapshot.exists()) {
        await set(deviceRef, { createdBy: user.uid });
      }

      const profilesRef = ref(database, `devices/${formData.deviceId}/profiles`);
      const newProfileRef = push(profilesRef);
      const profileData = {
        ...formData,
        picture: imageUrl,
        showEducation,
        showWellness,
        createdAt: Date.now(),
        createdBy: user.uid
      };

      await set(newProfileRef, profileData);

      if (!deviceSnapshot.child('defaultProfileId').exists()) {
        await set(ref(database, `devices/${formData.deviceId}/defaultProfileId`), newProfileRef.key);
      }

      await set(ref(database, `users/${user.uid}/devices/${formData.deviceId}`), true);

      alert('Registration Successful');
      
      if (location.pathname === '/newregistration') {
        navigate('/dashboard');
      }else{
        await signOut(auth);
        navigate('/login');
      }
      
    } catch (error) {
      console.error("Error submitting form:", error);
      alert('An error occurred while submitting the form: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  }
  const classOptions = ['IFC', 'PG', 'N1', 'N2', 'K1', 'K2'];
  const dietOptions = ['Vegeterian', 'Vegan', 'Non-Vegeterian', 'Non-Vegeterian(Halal)'];

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, height: "100vh", overflow: "auto" }}>
      <Box sx={{ width: '100%', height: 200, overflow: 'hidden' }}>
        <img
          src={banner}
          alt="InstaLink wristband and phone"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
      <CardHeader
        title="InstaShare [Pilot from 26th Aug'24 - 6th Sep'24]"
        subheader={
          <Typography variant="body2" color="text.secondary">
            InstaLink, is a wristband that any second person can tap their phone on to instantly get pre configured information.
            <br />
            Note: Each participating child will be given a wrist band which is safe and waterproof.
            <br /><br />

            Any files that are uploaded will be shared outside of the organisation they belong to.
          </Typography>
        }
      />

      <CardContent>
        <form onSubmit={handleSubmit}>
          {urlDeviceId ? (
            <TextField
              fullWidth
              margin="normal"
              name="deviceId"
              label="Device ID"
              value={formData.deviceId}

              required
              disabled
              helperText="Device ID from URL"
            />


          ) : (
            <TextField
              fullWidth
              margin="normal"
              name="deviceId"
              label="Device ID"
              value={formData.deviceId}
              onChange={handleInputChange}
              required
              helperText={`Enter the unique ID of the ${formData.deviceType}`}
            />

          )}

          <TextField
            fullWidth
            margin="normal"
            select
            label="Profile Type"
            name="profileType"
            value={formData.profileType}
            onChange={handleInputChange}
          >
            <MenuItem value="kids">Kids</MenuItem>
            <MenuItem value="patient">Patient</MenuItem>
            <MenuItem value="business">Business</MenuItem>
          </TextField>
          <div>
            <span>Personal Data:</span>
          </div>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="nricFin"
            label="NRIC/FIN"
            value={formData.nricFin}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="localAddress"
            label="Home Address"
            multiline
            rows={3}
            value={formData.localAddress}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="permanentAddress"
            label="Permanent Address"
            multiline
            rows={3}
            value={formData.permanentAddress}
            onChange={handleInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <span>Education</span>
            </div>

            <Box sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showEducation}
                  onChange={(e) => setShowEducation(e.target.checked)}
                  name="showEducation"
                />
              }
              label="Show Education Section"
            />
            </Box>
          </Box>


          <TextField
            fullWidth
            margin="normal"
            select
            name="school"
            label="School"
            value={formData.school}
            onChange={handleInputChange}
          >
            <MenuItem value="">Choose</MenuItem>
            <MenuItem value="school1">Leap SchoolHouse CSM</MenuItem>
            <MenuItem value="school2">Mind Champ CSM</MenuItem>
            <MenuItem value="school3">Other</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            name="institutionAddress"
            label="Institution Address"
            multiline
            rows={3}
            value={formData.institutionAddress}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="class-label">Class</InputLabel>
            <Select
              labelId="class-label"
              name="childClass"
              value={formData.childClass}
              onChange={handleInputChange}
              label="Child is in Class"
            >
              <MenuItem value="">Choose</MenuItem>
              {classOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="ClassTeacher"
            label="Class Teacher"
            value={formData.ClassTeacher}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="principal"
            label="Principal"
            value={formData.principal}
            onChange={handleInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <span>Wellness</span>
            </div>

            <Box sx={{ mt: 2, mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showWellness}
                    onChange={(e) => setShowWellness(e.target.checked)}
                    name="showWellness"
                  />
                }
                label="Show Wellness Section"
              />
            </Box>
          </Box>
     
          <TextField
            fullWidth
            margin="normal"
            name="bloodGroup"
            label="Child's Blood Group"
            value={formData.bloodGroup}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="class-label">Diet</InputLabel>
            <Select
              labelId="class-label"
              name="diet"
              value={formData.diet}
              onChange={handleInputChange}
              label="Diet"
            >
              <MenuItem value="">Choose</MenuItem>
              {dietOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            name="dietInstructions"
            label="Diet Instructions"
            value={formData.dietInstructions}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="allergies"
            label="Child is Allergic to"
            value={formData.allergies}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="medication"
            label="Medication"
            value={formData.medication}
            onChange={handleInputChange}
          />


          <TextField
            fullWidth
            margin="normal"
            name="medicalCondition"
            label="Any known Medical Condition"
            multiline
            rows={3}
            value={formData.medicalCondition}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="insurance"
            label="Insurance"

            value={formData.insurance}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="doctor"
            label="Doctor/Hospital"

            value={formData.doctor}
            onChange={handleInputChange}
          />

          <TextField
            fullWidth
            margin="normal"
            name="comments"
            label="Comments"

            value={formData.comments}
            onChange={handleInputChange}
          />

          <div>
            <span>Emergency</span>
          </div>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Emergency Contacts</Typography>
            {formData.emergencyContacts.map((contact, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={contact.name}
                      onChange={(e) => handleEmergencyContactChange(index, 'name', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Number"
                      value={contact.number}
                      onChange={(e) => handleEmergencyContactChange(index, 'number', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {index > 0 && (
                      <IconButton onClick={() => removeEmergencyContact(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addEmergencyContact}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              Add Emergency Contact
            </Button>
          </Grid>


          <Box sx={{ mt: 2, mb: 2 }}>
            <span>Additional Information</span>
          </Box>

          <TextField
            fullWidth
            margin="normal"
            name="additionalInformation"
            label="Additional Information"
            multiline
            rows={3}
            value={formData.additionalInformation}
            onChange={handleInputChange}
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle1">
              Upload picture (this is display picture)
            </Typography>
            <Typography variant="caption">
              Upload 1 supported file. Max 10 MB.
            </Typography>
            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="child-picture-upload"
            />
            <label htmlFor="child-picture-upload">
              <Button variant="outlined" component="span" startIcon={<span>↑</span>}>
                Add File
              </Button>
            </label>
            {formData.childPicture && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                File selected: {formData.childPicture.name}
              </Typography>
            )}
          </Box>

          <FormControl component="fieldset" sx={{ mt: 2 }} required>
            <FormLabel component="legend">
              I agree to share my child's data with InstaShare to pilot the project to enhanced safety and emergency response.
            </FormLabel>
            <RadioGroup
              aria-label="agreement"
              name="agreeToShare"
              value={formData.agreeToShare}
              onChange={handleInputChange}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={() => window.location.reload()}
              disabled={isSubmitting}
            >
              Clear form
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default InstaShareForm;
