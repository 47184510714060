// Profile.jsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { database, auth } from '../../firebase'; // Update the import path as needed
import { ref, get, push, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import ProfileViewMap from './ProfileViewMap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import {
    CircularProgress,
    Typography,
    Box,
    Button,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Divider,
    Grid,
    Paper,
    IconButton, 
    Switch
} from '@mui/material';

const profiles = [
    {
        id: 1, name: 'Praveen Sonare', nric: 'G3925151K', image: 'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/profile1.jpeg',
        blood_group: 'B+ve', allergies: "Skin Allergies", medical_conditions: "N.A.",
        emergency_name: 'Shrutika', emergency_no: '+6594485248', address: "#12-10, 32\nSturdee Road,\nSingapore - 207853"

    },
    {
        id: 2, name: 'Mireya Sonare', nric: 'G3934800X', image: 'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/rsz_whatsapp_image_2024-08-09_at_193750.jpg',

        blood_group: 'B +ve', allergies: "N.A", medical_conditions: "N.A",
        emergency_name: 'Praveen', emergency_no: '+6584052870', address: "#12-10, 32 Sturdee Road,\nSingapore - 207853"
    },
    {
        id: 3, name: 'Vaanya Bansal', nric: 'G3934800X', image: 'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/profile3.jpeg',

        blood_group: 'B +ve', allergies: "N.A", medical_conditions: "N.A",
        emergency_name: 'Rishbah Bansal', emergency_no: '+6598235054', address: "#22-07, 32 Sturdee Road,\nSingapore - 207853"
    },
    {
        id: 4, name: 'Ananya Kothari', nric: 'G3934800X', image: 'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/rsz_profile4.jpg',

        blood_group: 'B +ve', allergies: "N.A", medical_conditions: "N.A",
        emergency_name: 'Deepesh Kothari', emergency_no: '+6585697350', address: "#08-09, 36 Sturdee Road,\nSingapore - 207856"
    },

];

function Profile() {
    const [locationSent, setLocationSent] = useState(false);
    const { deviceId } = useParams();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
                checkOwnership(user.uid);
            } else {
                setIsAuthenticated(false);
                setIsOwner(false);
            }
        });

        const fetchDefaultProfile = async () => {
            setLoading(true);
            try {
                const deviceRef = ref(database, `devices/${deviceId}`);
                const deviceSnapshot = await get(deviceRef);

                if (deviceSnapshot.exists()) {
                    const deviceData = deviceSnapshot.val();
                    const defaultProfileId = deviceData.defaultProfileId;

                    if (defaultProfileId) {
                        const profileRef = ref(database, `devices/${deviceId}/profiles/${defaultProfileId}`);
                        const profileSnapshot = await get(profileRef);

                        if (profileSnapshot.exists()) {
                            setProfile(profileSnapshot.val());
                            sendLocation(deviceId); // Send location after profile is fetched
                        } else {
                            setError("Default profile not found");
                        }
                    } else {
                        setError("No default profile set for this device");
                    }
                } else {
                    setError("Device not found");
                }
            } catch (err) {
                setError("Error fetching profile: " + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDefaultProfile();
        return () => unsubscribe();
    }, [deviceId]);

    const checkOwnership = async (userId) => {
        try {
            const userDeviceRef = ref(database, `users/${userId}/devices/${deviceId}`);
            const snapshot = await get(userDeviceRef);
            setIsOwner(snapshot.exists());
        } catch (error) {
            console.error("Error checking ownership:", error);
        }
    };

    const sendLocation = (deviceId) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    saveProfileView(deviceId, latitude, longitude);
                },
                (error) => {
                    console.error("Error getting location:", error);
                    saveProfileView(deviceId, null, null);
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            saveProfileView(deviceId, null, null);
        }
    };

    const saveProfileView = async (deviceId, latitude, longitude) => {
        try {
            const profileViewsRef = ref(database, `profileViews/${deviceId}`);
            const newViewRef = push(profileViewsRef);

            await set(newViewRef, {
                timestamp: Date.now(),
                latitude: latitude,
                longitude: longitude
            });

            console.log("Profile view saved successfully");
        } catch (error) {
            console.error("Error saving profile view:", error);
        }
    };


    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters from the phone number
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        // Ensure the number starts with a country code (assuming Singapore +65)
        return cleanedNumber.startsWith('65') ? cleanedNumber : `65${cleanedNumber}`;
    };

    const DataItem = ({ label, value }) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary" align="left">{label}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" align="left">{value}</Typography>
                </Grid>
            </Grid>
        );
    };
    const DataSection = ({ title, data, visible }) => {
        if (!visible) return null;
        const filteredData = data.filter(item => item.value !== undefined && item.value !== null && item.value !== '');
        if (filteredData.length === 0) {
            return null;
        }
        return (
            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom align="left">{title}</Typography>
                {filteredData.map((item, index) => (
                    <DataItem key={index} label={item.label} value={item.value} />
                ))}
            </Paper>
        );
    };
 
    if (loading) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </Box>
        );
      }


    if (!profile) {
        return <div>Profile not found</div>;
    }


    if (loading) {
        // You can return a loading spinner or any other loading indicator here
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (<>
        <div className="inner-div">
            <div className="front">
                <div className="front__bkg-photo"></div>
                <div
                    className="front__face-photo"
                    style={{ backgroundImage: `url(${profile.picture})` }}
                ></div>
                <div className="front__text">
                    <h3 className="front__text-header">{profile?.name}</h3>
                    <h3 className="front__text-para">  NRIC/FIN: {profile?.nricFin}</h3>
                    <h3 className="front__text-para">  Blood Group: {profile?.bloodGroup}</h3>
                    <p className="front__text-para">  Address: {profile?.localAddress}</p>
                </div>

                <DataSection title="Personal Data" data={[
                    { label: "NRIC/FIN", value: profile.nricFin },
                    { label: "Local Address", value: profile.localAddress },
                    { label: "Permanent Address", value: profile.permanentAddress }
                ]} visible={true} />

                <DataSection title="Education" data={[
                    { label: "Institution Name", value: profile.institutionName },
                    { label: "Institution Address", value: profile.institutionAddress },
                    { label: "Class", value: profile.class },
                    { label: "Class Teacher", value: profile.classTeacher },
                    { label: "Principal", value: profile.principal }
                ]} visible={profile.showEducation}  />

                <DataSection title="Wellness" data={[
                    { label: "Blood Group", value: profile.bloodGroup },
                    { label: "Diet", value: profile.diet },
                    { label: "Diet Instructions", value: profile.dietInstructions },
                    { label: "Allergies", value: profile.allergies },
                    { label: "Medication", value: profile.medication },
                    { label: "Known Medical Condition", value: profile.medicalCondition },
                    { label: "Insurance", value: profile.insurance },
                    { label: "Doctor/Hospital", value: profile.doctor },
                    { label: "Comments", value: profile.medicalComments }
                ]} visible={profile.showWellness} />




                <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom align="left">Emergency Contacts</Typography>
                    {profile.emergencyContacts && profile.emergencyContacts.map((contact, index) => (
                        <Box key={index} sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body1" align="left">{contact.name}</Typography>
                                <Typography variant="body2" color="text.secondary" align="left">{contact.number}</Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    href={`tel:${contact.number}`}
                                    size="small"
                                    color="primary"
                                >
                                    <CallIcon />
                                </IconButton>
                                <IconButton
                                    href={`https://wa.me/${formatPhoneNumber(contact.number)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    size="small"
                                    color="primary"
                                >
                                    <WhatsAppIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Paper>

                <DataSection title="Additional Details" data={[
                    { label: "Additional Information", value: profile.additionalInformation },

                ]} />

            </div>

            {isOwner && (
                <Box mt={4} p={2}>
                    <Typography variant="h6" gutterBottom>
                        Band Tap Locations
                    </Typography>
                    <ProfileViewMap deviceId={deviceId} />
                </Box>
            )}
            {!isAuthenticated ? (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Box mt={2} textAlign="center">

                        <Button
                            component={Link}
                            to={`/login?redirect=/em/${deviceId}`}
                            variant="text"
                            color="primary"
                        >
                            Log In
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Box mt={2} textAlign="center">

                        <Button
                            component={Link}
                            to={`/dashboard`}
                            variant="text"
                            color="primary"
                        >
                            Dashboard
                        </Button>
                    </Box>
                </>
            )}
        </div>
    </>
    );
}

export default Profile;
