import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { database, auth } from '../../firebase'; // Update the import path as needed
import { ref, get } from 'firebase/database';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { 
  CircularProgress, 
  Typography, 
  Box, 
  Button, 
  Card, 
  CardContent 
} from '@mui/material';

const Device = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkDeviceAndAuth = async () => {
      setLoading(true);
      try {
        // Check if profile exists in database
        const profileRef = ref(database, `devices/${deviceId}`);
        const snapshot = await get(profileRef);

        if (snapshot.exists()) {
          setProfile(snapshot.val());
          navigate(`/em/${deviceId}`);
        } else {
          // Profile doesn't exist, check authentication
          if (user) {
            navigate(`/register/${deviceId}`);
          }
          // If not authenticated, the login options will be shown
        }
      } catch (error) {
        console.error("Error checking device:", error);
      } finally {
        setLoading(false);
      }
    };

    checkDeviceAndAuth();
  }, [deviceId, user, navigate]);

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        // User signed in, will trigger the useEffect to redirect
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  const handleAppleSignIn = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
      .then(() => {
        // User signed in, will trigger the useEffect to redirect
      })
      .catch((error) => {
        console.error("Error signing in with Apple:", error);
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!profile && !user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Card sx={{ maxWidth: 400, width: '100%' }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Login to Register Device
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Please sign in to register this device.
            </Typography>
            <Box sx={{ '& > button': { m: 1 } }}>
              <Button 
                variant="contained" 
                onClick={handleGoogleSignIn}
                fullWidth
              >
                Sign in with Google
              </Button>
      
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  // This return is just a fallback, the component should navigate away before reaching this point
  return null;
};

export default Device;