import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { database, auth } from '../../firebase'; // Adjust the import path as needed
import { ref, get } from 'firebase/database';

const markers = [
    {
      id: 1,
      name: "Chicago, Illinois",
      position: { lat: 41.881832, lng: -87.623177 }
    },
    {
      id: 2,
      name: "Denver, Colorado",
      position: { lat: 39.739235, lng: -104.99025 }
    },
    {
      id: 3,
      name: "Los Angeles, California",
      position: { lat: 34.052235, lng: -118.243683 }
    },
    {
      id: 4,
      name: "New York, New York",
      position: { lat: 40.712776, lng: -74.005974 }
    }
  ];

const ProfileViewMap = ({ deviceId }) => {
  const [viewLocations, setViewLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchViewLocations = async () => {
      const profileViewsRef = ref(database, `profileViews/${deviceId}`);
      try {
        const snapshot = await get(profileViewsRef);
        if (snapshot.exists()) {
          const locations = [];
          snapshot.forEach((childSnapshot) => {
            const view = childSnapshot.val();
            if (view.latitude && view.longitude) {
              locations.push({
                lat: view.latitude,
                lng: view.longitude,
                time: new Date(view.timestamp).toLocaleString()
              });
            }
          });
          setViewLocations(locations);
          if (locations.length > 0) {
            console.log('locations', locations)
            setMapCenter(locations[0]); // Center map on the first location
          }
        }
      } catch (error) {
        console.error("Error fetching view locations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchViewLocations();
  }, [deviceId]);

  if (loading) {
    return <div>Loading map...</div>;
  }

  if (viewLocations.length === 0) {
    return <div>No view locations available.</div>;
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyAC4adI5_5XusAtEBJkleRlvVouqpWnAVw">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={mapCenter}
        zoom={10}
      >
 
        {viewLocations.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            title={`Viewed on: ${location.time}`}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default ProfileViewMap;